@import "src/styles/variables";
.wrapper {
  //margin-bottom: 72px;
  position: relative;
  padding: 200px 0 0 0;
  .image {
    width: 100%;
    height: 240px;
    position: absolute;
    top:0;
    left: 0;
    background-position: center;
    background-size: cover;
    background-color: $gray;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    text-align: left;
    margin-bottom: 16px;
  }
  .description {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    margin-bottom: 16px;

  }
  .advantage {

    .icon {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $blue-l4;
      border-radius: 16px;
      i {
        color: $blue-d1;
        font-size: 24px;
      }
    }
    .advTitle {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
    }
    .advDescription {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      text-align: left;

    }
    .link {
      margin-top: 10px;
      margin-bottom: 10px;
      display: inline-block;
      border-bottom: 1px solid $blue-l1;
    }
  }
}