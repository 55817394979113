@import "src/styles/variables";

.wrapper {
  padding: 72px 0 88px 0;
  position: relative;
  .supText {
    color: $gray;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.12px;
    margin-bottom: 8px;
  }
  .supText:last-of-type  {
    margin-bottom: 0;
  }
}