.wrapper {
  padding: 72px 0px 88px 0px;
  &.loading{
    filter: blur(4px);
  }
  .tabs {
    :global {
      .ant-tabs-tab {
        white-space: initial;
        text-align: center;
        line-height: 16px;
      }
    }
  }
  .form {
    :global {
      .ant-form-item {

      }
      .ant-form-item-label {
        padding-bottom: 2px;

        label {
          height: 24px;
        }
      }
    }
  }
}