@import "src/styles/variables";
.wrapper {
  width: 100%;
  height: calc(100vh - 160px);
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .scanner {
    width: 220px;
    height: 220px;
  }
  video {
    position: fixed;
    width: 120% !important;
    height: 120%;
    top:49%;
    left:50%;
    transform: translate(-50%, -50%);
  }
  p {
    padding: 16px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    position: absolute;
    top: 0;
  }
  .buttonWrapper {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.32px;

    .button{
      padding: 12px;
      border-radius: 16px;
      border: 1px solid $blue-l4;
      color: $white;
      font-size: 24px;
    }
  }


  :global {
    #qr-shaded-region {
      border: none!important;
      outline: 1000px solid rgba(0,0,0,.8);
      height: 220px;
      width: 220px;
      left: 50% !important;
      transform: translate(-50%, -50%);
      top: 50% !important;
      border-radius: 16px;
      &:before {
        content:"";
        width: 250px;
        height: 250px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-image: url("../../../images/icons/qr-scanner-border.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      > div {
        display: none;
      }
    }
    #qr-canvas {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
}
.qrCodeView {
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}