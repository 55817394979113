.wrapper {
  padding: 72px 0 88px 0;
  position: relative;
  .radio {
    width: 100%;
    .item {
      padding: 16px 24px;
      :global {
        span.ant-radio+* {
          padding-inline-start: 16px;
        }
      }
    }
  }
}
