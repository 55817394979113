@import "src/styles/variables";
.lang {
  display: flex;
  align-items: center;
  padding: 10px;

  .text {
    font-weight: 600;
    width: 36px;
    padding-left: 8px;
    color: $black;
  }
  .flag {
    display: flex;
    svg {
      width: 18px;
      height: 18px;
    }
  }
  svg {
    width: 20px;
    height: 20px;
  }
}