@import "src/styles/variables";
.wrapper {
  //padding: 6px;
  background-color: $white;
  border-radius: 16px;
  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 16px;
  }
  .description {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    margin-bottom: 16px;

  }

  .image {
    width: 100%;
    height: 144px;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    background-color: $gray;
  }
  .button {
    margin: 8px 0;
  }
}