@import "src/styles/variables";
.wrapper {
  padding: 72px 0 88px 0;
  &.loading{
    filter: blur(4px);
  }

  .form {
    :global {
      .ant-form-item {
        margin-bottom: 10px;
      }
      .ant-form-item-row {
        //margin-top: 24px;
      }
      .ant-form-item-label {
        padding-bottom: 2px;

        label {
          height: 24px;
        }
      }
    }
    sub {
      color: $gray;
    }
    p {
      font-size: 14px;
      text-align: center;
      color: $gray;
    }
  }
}

.link {
  color: $gray;
  border-bottom: 1px solid $gray;
  margin: 0 auto;
  font-size: 16px;
  display: table;
  cursor: pointer;

  &:hover {
    color:$gray-l1;
    border-bottom: 1px solid $gray-l1;
  }
}