@import "src/styles/variables";

.wrapper {
  position: relative;
  padding:72px 0 88px 0;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 244px;
    top:0;
    left:0;
    background: linear-gradient(180deg, #343E4C 0%, #222932 100%);
    z-index: -1;
  }
  .title {
    color: $white;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;

    label {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      margin-bottom: 8px;
    }
  }
  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    gap: 6px;
    color: $red;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;

    i {
      font-size: 12px;
    }

  }
}
.modalPadding {
  padding: 24px;
  text-align: center;
}
.left {
  text-align: left;
  h4{
    margin-bottom: 4px;
  }
}