@import "src/styles/variables";
.modal {
  max-width:345px;
  :global {
    .ant-modal-content {
      padding: 0;
    }
  }
}
.item {
  display: flex;
  align-items: center;
  font-weight: 600;
  width: 100%;
  padding: 0 16px;
  .text {
    padding: 0 8px;
    font-weight: 500;
    font-size: 18px;
  }
  .arrow {
    margin-left: auto;
    display: flex;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      * {
        fill: $gray;
      }
    }
  }
}