@import "src/styles/variables";
.wrapper {
  :global {
    .ant-slider, .ant-slider:hover {
      .ant-slider-track {
        background-color: $blue;
      }

      .ant-slider-mark-text {
        color: $gray;
        top: 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;

      }
      .ant-slider-handle{
        &::after {
          width: 20px;
          height: 20px;
          top: -5px;
          left: -5px;
          background-color: $blue;
          border:2px solid $white;
          box-sizing: border-box;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }

    .ant-slider-dot {
      display: none;
    }
  }
}