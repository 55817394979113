@import "src/styles/variables";
.wrapper {
  padding: 72px 0 88px 0;
  position: relative;
  p {
    color: $gray;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;

  }
}