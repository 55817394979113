@import "src/styles/variables";
.wrapper {
  padding: 72px 0 88px 0;
  color: $gray;
  .textCenter {
    text-align: center;
  }
  .qr {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border:1px solid $blue-l4;
    border-radius: 16px;
  }
  .supText {
    color: $gray;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.12px;
    text-transform: uppercase;
  }
}