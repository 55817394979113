@import "src/styles/variables";
.input {
  padding: 11px 8px 11px 11px;
  color: $black;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  border: none;
  border-radius: 8px;
  box-shadow: 0 1px 1px 0 #0000001F !important;
  outline: none !important;

  &::placeholder {
    color: $gray;
  }
  i {
    color: $blue;
    font-size: 18px;
  }
  :global {
    .ant-input-prefix {
      margin-inline-end: 20px;
    }
  }
}