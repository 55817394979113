@import "src/styles/variables";
.wrapper {
  margin: 16px;
  padding: 16px;
  background-color: $color-white;
  border-radius: $radius-16;
  box-shadow: 0 1px 2px 0 #0000001F;
  position: relative;
  &.nopadding {
    padding: 0;
  }
  &.nomargin {
    margin: 0;
  }
  &.transparent {
    background-color: transparent;
    box-shadow: none;
  }
  &.left {
    align-items: flex-start;
  }
  &.height100 {
    height: 100%;
  }
}