@import "./src/styles/variables";

.wrapper {
  height: auto;

  .select {
    height: auto;

    :global{

      .ant-select-selector {
        height: auto !important;
        position: relative;
        padding: 8px !important;

        .ant-select-selection-item {
          line-height: 46px;
          padding: 1px 31px 1px 1px;
        }
        .ant-select-selection-placeholder {
          color:$gray;
          font-family: Gilroy;
          line-height: 48px;
          font-size: 15px;
          font-weight: 400;
          text-align: left;
          padding: 0 0 0 8px;
        }
        &:before {
          content: "";
          position: absolute;
          top: 10%;
          right: 30px;
          width: 1px;
          height: 80%;
          background-color: #E8E9EA;
        }
      }
      .ant-select-arrow {
        margin-top: -4px;
      }
      input {
        padding: 10px;
        height: 44px;
      }
    }
    .option {

    }
  }
}

.option {
  padding: 0;
}