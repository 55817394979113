@import "src/styles/variables";
.wrapper {
  padding: 72px 0 88px 0;
}
.date {
  padding: 6px 16px;
  background-color: $white;
  color: $black;
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 10px;
  &.new {
    background-color: $green;
    color: $white;
  }
}
.icon {
  width: 24px;
  height: 24px;
  i {
    font-size: 24px;
    color: $blue-d1;
  }
}
.title {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}
.text {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  b {
    font-weight: 700;
  }
}