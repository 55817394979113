@import 'src/styles/variables';

.wrapper {
  position: relative;
  padding: 72px 0 88px 0;
  h4 {
    color: $black;
    font-size: 16px;
    font-weight: 700;
    padding: 0 8px;
  }
  .item {
    color: $gray;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;

    .icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      i {
        font-size: 24px;
        color: $gray;
        &.success {
          color: $green;
        }
      }
    }
    .date {
      color: $black;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 8px;
    }
  }
}