@import "src/styles/variables";
.input {
  width: 100%;
  &.focus {
    border-color: $blue;
    :global {
      .ant-input-group {
        .ant-input-group-addon {
          border-color: $blue;
        }
      }
    }
  }
  :global {
    .ant-input-number, .ant-input-number-input-wrap, .ant-input-group {
      font-family: Gilroy;
      border-right: none;
      input {
        padding: 12px 16px;
        font-size: 15px;
        line-height: 20px;
        height: 46px;
        &::placeholder {
          font-family: Gilroy;
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
          color: $gray !important;
        }
      }
    }
    .ant-input-number-group-addon:last-child {
      background-color: transparent;
      border-left:none;
      position: relative;
      color: $black;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;

      &:after {
        content: "";
        position: absolute;
        top: 10%;
        left: 0;
        width: 1px;
        height: 80%;
        background-color: #E8E9EA;
      }
    }
  }
}
.annotation {
  font-family: "Gilroy";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;

  color: $gray;
}