@import "src/styles/variables";

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  .info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-right: auto;
    overflow: hidden;
    .label {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-align: left;
      color: $black;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .description {
      color: $gray;
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .value {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: $black;
    > div {
      text-align: right;
      white-space: nowrap;
    }
    .summ {
      color: $black;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      text-align: right;
      white-space: nowrap;
      &.success {
        color: $green;
      }
      &.process {
        color: $yellow;
      }
    }
    .cashback {
      font-size: 12px;
      font-weight: 800;
      line-height: 13px;
      text-align: right;
      border: 1px solid $yellow;
      border-radius: 50px;
      padding: 3px 7px 3px 7px;
      position: relative;
      display: inline-flex;
      white-space: nowrap;
      gap: 8px;
      svg {
        width: 14px;
        height: 14px;
      }
    }
    .payment {
      color: $gray;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-align: right;
      white-space: nowrap;
    }
    .debt {
      color: $red;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-align: right;
      white-space: nowrap;
    }
    .score {
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.04em;

    }
  }
  .card {
    background-color: $white;
    background-position: center;
    background-repeat: no-repeat;
    width: 57px;
    height: 45px;
    border-radius: 8px;
    outline: 1px solid $blue-l4;
    position: relative;
    flex-shrink: 0;
    margin: 1px;
    .number {
      color: $gray;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0em;
      white-space: nowrap;
      position: absolute;
      bottom: 6px;
      left: 50%;
      transform: translateX(-50%);

    }
    &.visa {
      background-image: url("../../../../images/icons/iconVisa.svg");
    }
    &.mc {
      background-image: url("../../../../images/icons/iconMC.svg");
    }
    &.uzCard {
      background-image: url("../../../../images/icons/iconUzCard.svg");
    }
    &.humo {
      background-image: url("../../../../images/icons/iconHumo.svg");
    }
  }
  .icon {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    outline: 1px solid $blue-l4;
    margin-right: 8px;
    i {
      color: $blue;
      font-size: 24px;
    }
    &.green {
      outline: 1px solid $green-l3;
      i {
        color: $green;
      }
    }
  }
}