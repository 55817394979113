.wrapper {
  height: 48px;

  .select {

    :global{
      .ant-select-selector {
        height: 48px!important;
        position: relative;

        .ant-select-selection-item {
          line-height: 46px;
        }
        .ant-select-selection-placeholder {
          line-height: 48px;
        }
        &:before {
          content: "";
          position: absolute;
          top: 10%;
          right: 30px;
          width: 1px;
          height: 80%;
          background-color: #E8E9EA;
        }
      }
      input {
        padding: 10px;
        height: 44px;
      }
    }
    .option {

    }
  }
}
