// Color
$color-black: #000000;
$color-white: #FFFFFF;
$color-background: #F0F2F5;


$green: #95D319;
$green-l1: lighten($green, 8%);
$green-l2: lighten($green, 16%);
$green-l3: lighten($green, 22%);
$green-l4: lighten($green, 38%);

$green-d1: darken($green, 8%);
$green-d2: darken($green, 16%);
$green-d3: darken($green, 22%);
$green-d4: darken($green, 38%);

$blue: #007CE9;
$blue-l1: lighten($blue, 8%);
$blue-l2: lighten($blue, 16%);
$blue-l3: lighten($blue, 22%);
$blue-l4: lighten($blue, 38%);
$blue-l5: lighten($blue, 50%);

$blue-d1: darken($blue, 8%);
$blue-d2: darken($blue, 16%);
$blue-d3: darken($blue, 22%);
$blue-d4: darken($blue, 38%);

$gray: #9B9B9B;
$gray-l1: lighten($gray, 32%);
$gray-l2: lighten($gray, 35.2%);

$red: #E53935;
$violet: #6A1B9A;
$yellow: #FFC107;
$black: #2D3540;
$white: #FFFFFF;

$color-grey-light: #EDEDED;

$color-blue-1: #D1E9FF;


// Transition
$ui-transition:all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
$ui-transition-none: all 0ms;

// Font size
$font-size-h1: 24px;
$font-size-h2: 18px;
$font-size-main: 16px;
$font-size-small: 14px;
$font-size-ultrasmall: 12px;
$font-size-caption: 12px;

// Font line-hight
$font-line-h1: 30px;
$font-line-h2: 24px;
$font-line-main: 22px;
$font-line-small: 20px;
$font-line-ultrasmall: 18px;
$font-line-caption: 24px;

// Font weight
$font-weight-semibold: 600;
$font-weight-regular: 400;

//Radius
$radius-16: 16px;



