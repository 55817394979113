@import "src/styles/variables";
.head {
  background-color: $color-white;
  padding: 16px;
  min-height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top:0;
  left:0;
  right: 0;
  z-index: 1;
  .empty {
    width: 24px;
  }
  .loginBtn {
    display: flex;
    align-items: center;
    font-weight: 600;
    text-decoration: none;
    color: $black;
    line-height: 16px;
    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      color: $gray;
      border: 1px solid $color-grey-light;
      border-radius: $radius-16;
      margin-right: 8px;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .settings {
      color: $gray;
      font-size: 12px;
      line-height: 16px;
    }
  }
  .notification {
    display: flex;
    margin-right: 10px;
    i {
      font-size: 24px;
      color: $blue;
    }
    :global{
      .ant-badge {
        .ant-badge-count {
          min-width: 16px;
          height: 16px;
          font-size: 10px;
          line-height: 16px;
          font-weight: 900;
          right:2px;
          top:4px;
          box-shadow: none;
        }
      }
    }
  }
  .title {
    font-size: 18px;
    font-weight: 600;
    color: $black;
  }
  .infoIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 24px;
      color: $blue;
    }
  }
  &.transparent {
    background-color: #343E4C;
    .loginBtn {
      svg * {
        fill: $white;
      }
    }
    .title {
      color: $white;
    }
    .infoIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 24px;
        color: $white;
      }
    }
  }
  &.danger {
    background-color: $red;
  }
}