@import "src/styles/variables";
.wrapper {
  padding: 72px 0px 88px 0px;
  .btn {
    background-color: $blue-l4;
    z-index: 2;
    height: 36px;
    margin-right: -5px;
    border: none;
  }
  .result {
    div {
      height: 24px;
    }
  }
}