@import "../../../../styles/variables";
.menuWrapper {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  height: 88px;
  background: $white;
  width: 100%;
  border-top: 2px solid $blue;
  box-sizing: border-box;

  .menu {
    width: 100%;
    border: none;

    .menuItem {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      color: $gray;
      padding: 11px 6px;

      i {
        font-size: 24px;
      }
    }
    :global {
      .ant-menu-item {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        color: $gray;
        padding: 11px 6px;
        width: calc(25% - 0.25px);
        gap: 8px;
        line-height: 8px;
        position: relative;
        i {
          font-size: 24px;
        }
        &.ant-menu-item-selected, &.ant-menu-item-active {
          color: $blue;
          i, a {
            color: $blue;
          }
        }
        &:before {
          content: '';
          position: absolute;
          top:0;
          bottom: 0;
          left: 0;
          width: 1px;
          background-color: $gray-l1;
        }
        &:first-of-type {
          &:before {
            display: none;
          }
        }
        &:after {
          display: none;
        }
      }
      .ant-menu-title-content {
        //line-height: 26px;
      }
    }
  }
}
