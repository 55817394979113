.wrapper {
  padding:  72px 0 88px 0;
  position: relative;
  label {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 12px;
    display: block;
  }
}