@import "src/styles/variables";
.wrapper {
  padding: 72px 0 88px 0;
  position: relative;
  .item {
    label {
      color: $black;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
    }
    .value {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }
  }
  .qr {
    display: flex;
    justify-content: center;
    padding: 8px;
    border-top: 1px solid $gray-l1;
  }
}
.modalPadding {
  padding: 24px;
  text-align: center;
}
