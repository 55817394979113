@import "src/styles/variables";
.wrapper {
  .item {
    display: flex;
    align-items: center;
    gap: 20px;
    .icon {
      width: 45px;
      height: 45px;
      border-radius: 8px;
      border: 1px solid $blue-l4;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        width: 26px;
        height: 26px;
        border-radius: 0px;
        vertical-align: middle;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        //background-color: $gray-l1;
      }
    }
    .title {
      color: $black;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
    }
    .description {
      color: $gray;
      font-size: 13px;
      font-weight: 500;
      line-height: 15px;
      margin-top: 4px;
    }

  }
  :global {
    .ant-list-item {
      padding: 12px 0;
    }
  }
}