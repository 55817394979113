@import "src/styles/variables";
:global {
  .ant-form-item {
    position: relative;
    &.ant-form-item-has-error{
      .ant-checkbox-inner {
        border-color: $gray-l1;
      }
    }
    .ant-form-item-control {
      .ant-form-item-control-input {
        min-height: 20px;
      }
    }
  }
  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled), .ant-checkbox-checked  {
    &:after {
      border-color: $blue;
    }
    .ant-checkbox-inner {
      background-color: $blue;
    }
  }
  .ant-checkbox-checked:after {
    border-color: $blue;
  }
}

.checkbox {
  align-items: start ;
  display: flex;
  :global {
    span:not(.ant-checkbox-inner) {
      top:initial;
    }
    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      &:after {
        width: 6px;
        inset-inline-start: 28%;
        height: 10px;
      }
    }
  }
}