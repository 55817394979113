
.input {
  font-family: "Gilroy";
  padding: 14px 16px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 100;
  text-align: left;

  :global {
    .ant-input-prefix {
      margin-left: -4px;
      margin-right: 24px;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        top: -9px;
        right: -14px;
        width: 1px;
        height: 36.8px;
        background-color: #E8E9EA;
      }
    }
  }
}