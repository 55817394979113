.input {
  padding: 14px 16px;
  font-size: 16px;
  line-height: 18px;

  &::placeholder{
    color: #9B9B9B;
    font-size: 16px;
    line-height: 18px;
  }
  &:hover {
    border-color:#E8E9EA;
    box-shadow: none;
    outline: none;
  }
}

:global {
  .ant-form-item-has-error {
    :local {
      .input {
        border-color: #ff4d4f;
      }
    }
  }
}