@import "src/styles/variables";
.wrapper {
  width: 100%;
  height: 208px;
  border: 1px solid #54647A;
  background: linear-gradient(180deg, #505B6B 0%, #39424E 100%);
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 4px 0 #00000040;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  label {
    color: $gray;
    font-size: 11px;
    font-weight: 400;
    text-align: left;
    display: inline-block;
  }

  i {
    font-size: 24px;
  }

  .balance {
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: 700;
    line-height: 16px;
    text-align: left;
    color: $white;

    label {
      color: $white;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      margin-bottom: 8px;
    }
  }
  .bonus {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    label {
      margin-bottom: 6px;

    }

    .bonusValue {
      font-size: 12px;
      font-weight: 800;
      line-height: 13px;
      text-align: right;
      background-color: $black;
      color: $white;
      border-radius: 50px;
      padding: 3px 7px 3px 7px;
      position: relative;
      display: inline-flex;
      white-space: nowrap;
      gap: 8px;
      svg {
        width: 14px;
        height: 14px;
        * {
          fill: $yellow;
        }
      }
    }
  }
  .cardInfo {
    margin-top: auto;
    label {
      margin-bottom: 4px;
    }
  }
  .info {
    color: $white;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;

  }
  .infoReq {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;

  }
  .cardLogo {
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    svg {
      height: 32px;
    }
  }
  .copy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn {
      padding: 6px;
      i {
        color: $blue-l4;
      }
    }
  }
  .footer {
    margin-top: 24px;
  }
}