@import "src/styles/variables";
.input {
  :global {
    .ant-select {
      width: 54px !important;
    }
    .ant-input-group {
      .ant-input-group-addon:last-child {
        i {
          color: $blue;
        }
        &:before {
          left:0;
        }
      }
      >.ant-input:not(:first-child):not(:last-child) {
        border-right: none;
      }
    }
    .ant-select-selector {
      padding: 8px 8px 8px 4px !important;
      height: 100% !important;
      border: 0 !important;
      box-shadow: none !important;
    }
    .ant-select-dropdown {
      .ant-select-item {
        padding: 5px !important;
      }
    }
    .ant-input-group-addon {
      background-color: transparent !important;
      position: relative;
      border-color: $gray-l1;

      &:before {
        content: "";
        position: absolute;
        top: 10%;
        right: 0;
        width: 1px;
        height: 80%;
        background-color: $gray-l1;
      }
      &:after {
        display: none;
      }
    }
    .ant-select-selection-item {
      padding-inline-end: 0px !important;
      color: $black;
      font-size: 15px;
    }
    input {
      padding: 12px 16px;
      font-size: 15px;
      line-height: 20px;
      border-left: 0;
      position: relative;
      border-color: $gray-l1;
      >.ant-input:not(:first-child):not(:last-child) {

      }

      &::placeholder{
        color: $gray;
        font-size: 15px;
        line-height: 20px;
      }
      &:hover {
        border-color: $gray-l1;
        box-shadow: none;
        outline: none;
      }
    }
  }
}

:global {
  .ant-form-item-has-error {
    :local {
      .input {
        :global {
          .ant-input-group-addon, input {
            border-color: $red;
          }
        }
      }
    }
  }
}