@import "src/styles/variables";

.button {
  color: $white;
  padding: 16px;
  box-shadow: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  border-radius: 11px !important;
  height: auto !important;
  > span {
    font-size: 16px;
    font-weight: 600;
  }
  &.small {
    padding: 10px;
    line-height: 17px;
    font-size: 14px;
    > span {
      font-size: 15px;
      font-weight: 600;
    }
  }

  &:not(:disabled){
    background-color: $blue;
    &:hover {
      background-color: $blue-l1;
    }
  }
  &.secondary {
    background-color: $green;
    border-color: $green;
    color: $white;
  }
  &.bordered {
    background-color: $white;
    border-color: $blue;
    color: $blue;
    &:disabled {
      background-color: $blue-l5;
      border-color: $blue-l4;
      color: $blue-l3;
    }
  }
  &.danger {
    background-color: $red;
    border-color: $red;
    color: $white;
    &:disabled {
      background-color: lighten($red, 20%);
      border-color: lighten($red, 20%);
    }
  }
  &.default {
    background-color: transparent;
    border-color: #3F8B76;
    color: #3F8B76;
  }
  &.disabled {
    background-color: $blue-l4;
  }
}