.wrapper {
  padding: 72px 0 88px 0;
  position: relative;
}
.title {
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: left;
  display: block;
}
.description{
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  display: block;
  margin-top: 4px;
}