@import "./styles/variables";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /*background: var(--tg-theme-bg-color);*/
  background-color: $color-background;
  color: #000000;
  font-family: 'Gilroy';
  font-weight: 400;
}

h2 {
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
}

h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}

h4 {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 8px;
}

p {
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}

ol {
  padding-left: 16px;
  font-family: 'Gilroy';
  li {
    margin-top: 1em;
    &:first-child {
      margin-top: 0;
    }
  }
}

a {
  color: $blue-l1;
}

input {
  box-shadow: none !important;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

:global{

  .ant-input-affix-wrapper-status-error,
  .ant-input-group-wrapper-status-error .ant-input,
  .ant-input-group-wrapper-status-error .ant-input-group-addon{
    border-color: $red !important;
  }
  .ant-input {
    border-radius: 8px;
  }
  .ant-input-outlined {
    border-color: $gray-l1;
  }
  .ant-input-group-wrapper {
    .ant-input-group {
      > .ant-input {
        &:first-child {
          border-right: none;
        }
      }
      .ant-input-group-addon {
        &:last-child {
          background-color: transparent;
          color: black !important;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            top: 10%;
            right: 46px;
            width: 1px;
            height: 80%;
            background-color: $gray-l1;
            z-index: 1;
          }
        }
      }
    }
  }
  .ant-input-group-wrapper-outlined {
    .ant-input-group-addon {
      border-color: $gray-l1;
    }
  }
  .ant-form {
    .ant-form-item {
      .ant-form-item-label {
      >label {
        font-family: "Gilroy";
        height: auto;
      }
      }
    }
  }
  textarea.ant-input{
    color: $black;
    padding: 16px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    &::placeholder {
      color: $gray;
    }
  }
  .ant-form-item-has-error .ant-form-item-explain-error {
    color:$red;
    line-height: 1.25;
    margin-bottom: 5px;
  }
  .ant-select {
    .ant-select-selection-placeholder {
      color: $gray;
    }
    .ant-select-arrow {
      inset-inline-end: 8px;
      height: 12px;
      margin-top: -2px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
  .ant-select-outlined:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-color: $gray-l1;
    }
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) {
    .ant-select-selector {
      padding: 0 14px;
    }
  }
  .ant-form-item {
    .ant-form-item-label {
      line-height: 18px;
      height: auto;
      padding-bottom: 6px;
      > label {
        font-size: 15px;
        height: auto;
      }
    }
    input {
      font-size: 15px;
      line-height: 20px;
      padding: 12px 16px;

      &::placeholder {
        font-size: 15px;
        color: $gray;
      }
    }
    label {
      font-weight: 500;
      font-size: 15px;
    }
  }
  .ant-tabs {
    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $blue;
      }
    }

    .ant-tabs-ink-bar {
      color: $blue;
      background: $blue;
    }
    .ant-tabs-tab-btn {
      &:active {
        color: #7F7F7F;
      }
    }
  }
  .ant-modal-root {
    .ant-modal-mask {
      background-color: $black;
    }
  }
  .ant-modal {
    .ant-modal-content {
      border-radius: 16px;
    }
  }

  ul.list {
    color: $blue;
    li {
      color: $black;
      vertical-align: text-bottom;
      font-size: 15px;
      line-height: 22px;
      list-style-type: none;
      position: relative;
      padding-left: 15px;
      &:before {
        position: absolute;
        color: $black;
        content: "•";
        padding-right: 10px ;
        top: 1px;
        left: 0;
        font-size: 14px;
      }
      li:before {
        font-size: 7px;
        content: "·";
      }
    }
  }
  .ant-upload-wrapper {
    .ant-upload {
      width: 100%;
    }
  }
  .ant-checkbox-wrapper {
    font-family: 'Gilroy';
    line-height: normal;
  }
  .ant-badge {
    .ant-badge-count {
      background: $red;
    }
  }
  .ant-collapse {
    font-family: 'Gilroy';
    .ant-collapse-item {
      .ant-collapse-header {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
      }
    }
  }
  .ant-list {
    font-family: 'Gilroy';
    .ant-list-item {
      padding: 8px 0;
    }
  }
  .ant-radio-wrapper {
    .ant-radio {
      .ant-radio-inner {
        border-color: $gray-l1;
        background-color: $white;
        &:after {
          background-color: $blue;
          transform: scale(0.5);
        }
      }
      &:after {
        border: none;
      }
    }
  }
  .ant-progress {
    margin: 0 0 4px 0;
    .ant-progress-bg {
      background-color: $green;
    }
  }
  .ant-notification {
    .ant-notification-notice-wrapper {
      border-radius: 11px;
      .ant-notification-notice {
        width: calc(100vw - 32px);
        max-width: calc(100vw - 32px);
        padding: 24px 16px;

      }
      .ant-notification-notice-with-icon {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .ant-notification-notice-icon {
          position: relative;
          text-align: center;
        }
        .ant-notification-notice-message {
          margin: 0;
          text-align: center;
          margin-inline-start: 0;
          font-size: 21px;
          font-weight: 700;
          line-height: 25.39px;
        }
        .ant-notification-notice-description {
          margin: 0;
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 16px;
          margin-inline-start: 0;
          font-size: 16px;
          font-weight: 500;
          line-height: 18.83px;
          small {
            color: $gray;
            text-align: center;
            font-size: 13px;
            line-height: 15.3px;
            font-weight: 300;
          }
        }
      }
    }
  }

  .react-daterange-picker {
    font-family: "Gilroy";
    .react-daterange-picker__wrapper {
      &:before {
        content: "c";
        font-size: 13px;
        font-weight: 500;
        line-height: 15.3px;
      }
      border: none;
      display: flex;
      justify-content: center;
      gap: 2px;
      color: $gray;
      > div, > span {
        color:$gray;
        width: auto;
        min-width: auto;
        flex-grow: initial;
        font-size: 13px;
        font-weight: 500;
        line-height: 15.3px;
      }
      .react-daterange-picker__button {
        display: none;
      }
      .react-daterange-picker__inputGroup {
        .react-daterange-picker__inputGroup__input:invalid {
          background-color: transparent;
        }
      }
    }
    .react-calendar {
      font-family: "Gilroy";


      .react-calendar__viewContainer {
        .react-calendar__month-view__weekdays {
          .react-calendar__month-view__weekdays__weekday {
            color: $gray;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            abbr {
              text-decoration: none;
            }
          }
        }
      }
      .react-calendar__tile {
        height: 40px;
        font-family: Gilroy;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.56px;
        position: relative;
        abbr {
          position: relative;
        }
        &.react-calendar__tile--active, &.react-calendar__tile--hover {
          color: $black;
          background-color: $green-l4;
          &:enabled {
            background-color: transparent;
            &:not(.react-calendar__tile--rangeStart), &:not(.react-calendar__tile--rangeEnd){
              background-color: $green-l4;
            }
          }

          &.react-calendar__tile--rangeStart {
            color: $white;
            position: relative;
            &:not(.react-calendar__tile--rangeEnd){
              background: rgb(54,0,0);
              background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, $green-l4 50%, $green-l4 100%);
            }
            &:before {
              content: "";
              position: absolute;
              top:50%;
              left:50%;
              transform: translate(-50%, -50%);
              background-color: $green;
              width: 40px;
              height: 40px;
              border-radius: 40px;
            }
          }
          &.react-calendar__tile--rangeEnd {

            color: $white;
            position: relative;
            &:not(.react-calendar__tile--rangeStart){
              background: rgb(54,0,0);
              background: linear-gradient(90deg, $green-l4 0%, $green-l4 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
            }
            &:before {
              content: "";
              position: absolute;
              top:50%;
              left:50%;
              transform: translate(-50%, -50%);
              background-color: $green;
              width: 40px;
              height: 40px;
              border-radius: 40px;
            }
          }
        }
        &.react-calendar__tile--hasActive {
          background: transparent;
          &.react-calendar__tile--rangeStart{
            color: $white;
            background: rgb(54,0,0);
            background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, $green-l4 50%, $green-l4 100%);
            &:before {
              content: "";
              position: absolute;
              top:50%;
              left:50%;
              transform: translate(-50%, -50%);
              background-color: $green;
              width: 40px;
              height: 40px;
              border-radius: 40px;
            }
          }
          &.react-calendar__tile--rangeEnd{
            color: $white;
            background: rgb(54,0,0);
            background: linear-gradient(90deg, $green-l4 0%, $green-l4 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
            &:before {
              content: "";
              position: absolute;
              top:50%;
              left:50%;
              transform: translate(-50%, -50%);
              background-color: $green;
              width: 40px;
              height: 40px;
              border-radius: 40px;
            }
          }
          &:before {
            content: "";
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%, -50%);
            background-color: $green;
            width: 40px;
            height: 40px;
            border-radius: 40px;
          }
        }
        &.react-calendar__tile--now {
          background: transparent;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            transform: initial;
            top:5px;
            right:5px;
            left: initial;
            background-color: $yellow;
            width: 5px;
            height: 5px;
            border-radius: 40px;
          }
        }
      }
      .react-calendar__navigation {

        .react-calendar__navigation__arrow {
          color: transparent;
          position: relative;
          background-color: transparent;
          &:before {
            position: absolute;
            content: "";
            width: 24px;
            height: 24px;
            background-size: 24px;
            left: 50%;
            top:50%;
            transform: translate(-50%,-50%);
          }

          &.react-calendar__navigation__prev2-button {
            &:before {
              background: url("../src/images/icons/double-arrow-left.svg");
            }
          }
          &.react-calendar__navigation__next2-button {
            &:before {
              background: url("../src/images/icons/double-arrow-right.svg");
            }
          }
          &.react-calendar__navigation__prev-button {
            &:before {
              background: url("../src/images/icons/arrow-left.svg");
            }
          }
          &.react-calendar__navigation__next-button {
            &:before {
              background: url("../src/images/icons/arrow-right.svg");
            }
          }
        }
        .react-calendar__navigation__label {
          font-family: "Gilroy";
          font-size: 16px;
          font-weight: 500;
          line-height: 18.83px;
          &:enabled {
            background-color: transparent;
          }
        }
      }

      .react-calendar__year-view, .react-calendar__decade-view, .react-calendar__century-view {
        .react-calendar__tile {
          height: auto;
          padding: 19px 5px;
          &.react-calendar__tile--hasActive {
            background-color: $green-l4;
            &.react-calendar__tile--rangeStart {
              background-color: $green;
              &:not(.react-calendar__tile--rangeEnd){
                background: rgb(54,0,0);
                background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, $green-l4 50%, $green-l4 100%);
              }
              &:before {
                content: "";
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%, -50%);
                background-color: $green;
                width: 100%;
                height: 100%;
                border-radius: 40px;
              }
            }
            &.react-calendar__tile--rangeEnd {
              background-color: $green;
              &:not(.react-calendar__tile--rangeStart){
                background: rgb(54,0,0);
                background: linear-gradient(90deg, $green-l4 0%, $green-l4 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
              }
              &:before {
                content: "";
                position: absolute;
                top:50%;
                left:50%;
                transform: translate(-50%, -50%);
                background-color: $green;
                width: 100%;
                height: 100%;
                border-radius: 40px;
              }
            }
          }
        }
      }
    }
  }



}