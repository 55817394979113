@import "src/styles/variables";

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $gray;
  font-size: 13px;
  line-height: 15px;
  .title {
    color: $black;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }
  i {
    font-size: 24px;
    color: $yellow;
  }
}