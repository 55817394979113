@import "src/styles/variables";
.wrapper {
  padding: 0px 0 88px 0;
  position: relative;
  .info {
    display: flex;
    align-items: center;
    justify-content: start;
    .avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      color: $gray;
      border: 1px solid $color-grey-light;
      border-radius: $radius-16;
      margin-right: 16px;
      font-size: 21px;
      font-weight: 600;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .name {
      font-size: 21px;
      line-height: 25px;
      font-weight: 700;
      color: $black;
      .phone {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
      }
    }
  }
  .contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .mail, .pinfl{
      width: calc(50% - 21px);
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      text-transform: uppercase;
      color: $black;
      > div {
        color: $gray;
        text-transform: lowercase;
        font-weight: 400;
      }
    }

    .divider {
      width: 41px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 1px;
        height: 25px;
        top:-12px;
        right: 50%;
        background-color: $gray;
      }
    }
  }
}
.item {
  display: flex;
  align-items: center;
  font-weight: 600;
  width: 100%;
  padding: 0 8px;
  i {
    color: $blue;
  }
  .text {
    padding: 0 8px;
    font-weight: 500;
    font-size: 18px;
  }
  .arrow {
    margin-left: auto;
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      * {
        fill: $black;
      }
    }
  }
}


