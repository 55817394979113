@import "src/styles/variables";
.wrapper {
  padding: 72px 0 88px 0;
  position: relative;

  p {
    font-size: 13px;
    color: $gray;
    margin-bottom: 12px;
  }
}