.slideBlock {
  padding: 0 8px;
}

.slideItem {
  border-radius: 12px;
  color: #A4A4A4;
  font-size: 13px;

  //background: #D9D9D9;
  overflow: hidden;
  background-size: cover;
  .innerWrapper {
    font-weight: 100;
    padding: 12px;
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    flex-direction: column;
    position: absolute;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    .anticon-close {
      svg {
        path: #ffffff;
      }
    }
    .text {
      width: 100%;
      text-align: center;
      margin-top: auto;
      * {
        display: block;
        line-height: 12px;
      }
    }
    .icon{
      height: 50px;
      margin: 0 auto;
    }
  }
  .banner {
    width: 100%;
    display: block;
  }

  /*&:before {
    content: "";
    padding-top: 100%;
    float: left;
  }*/
}

.wrapper {
  :global {
    .alice-carousel__dots,.alice-carousel__prev-btn, .alice-carousel__next-btn {
      display: none;
    }
  }
}