@import "src/styles/variables";
.item {
  display: flex;
  align-items: center;
  font-weight: 600;
  width: 100%;
  padding: 8px 16px;
  .text {
    padding: 0 16px;
    font-weight: 500;
    font-size: 18px;
  }
  .arrow {
    margin-left: auto;
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      * {
        fill: $black;
      }
    }
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    i {
      color: $blue;
      font-size: 24px;
    }
    &.borderIcon {
      padding: 8px;
      border: 1px solid $blue-l4;
      border-radius: 10px;
      i {
        font-size: 22px;
      }
    }
  }
}

.title {
  margin-bottom: 4px;
}

.picker {
  display: block !important;
  position: relative;
  :global {
    .react-daterange-picker__calendar {
      display: block !important;
      position: relative !important;
      width: 100%;
      height: auto !important;
      .react-calendar {
        border: none;
      }
    }
  }
}