@import "src/styles/variables";
.wrapper {
  padding: 72px 0 88px 0;
}

.item {
  display: flex;
  align-items: center;
  font-weight: 600;
  width: 100%;
  padding: 0 8px;
  i {
    color: $blue;
  }
  .text {
    padding: 0 8px;
    font-weight: 500;
    font-size: 18px;
  }
  .arrow {
    margin-left: auto;
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      * {
        fill: $black;
      }
    }
  }
}