.wrapper {
  padding: 72px 0px 88px 0px;
  &.loading{
    filter: blur(4px);
  }
  .subTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: center;
    margin-top: 16px;
  }
  .small {
    font-size: 13px;
    line-height: 15px;

  }
  .tabs {
    :global {
      .ant-tabs-tab {
        white-space: initial;
        text-align: center;
        line-height: 16px;
      }
    }
    ol {
      font-size: 13px;
      line-height: 15px;
    }
  }
  .form {
    :global {
      .ant-form-item {

      }
      .ant-form-item-label {
        padding-bottom: 2px;

        label {
          height: 24px;
        }
      }
    }
  }
}