@import "src/styles/variables";

.wrapper {
  position: relative;
  padding: 72px 0 88px 0;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 274px;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #343E4C 0%, #222932 100%);
    z-index: -1;
  }
  &.danger:before {
    background: linear-gradient(180deg, #F04743 0%, #E53935 100%);
  }
  .block {
    color: $black;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    &.textRight {
      text-align: right;
    }
    label {
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      display: block;
    }
  }
  .title {
    color: $white;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;

    label {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      text-align: center;
      margin-bottom: 8px;
    }
    .paymentPeriod {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      margin-top: 16px;
    }
  }
}
.modalPadding {
  padding: 24px;
  text-align: center;
  .listItem {
    padding: 12px 0;
  }
  .listItemRow {
    width: 100%;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    text-align: left;

    .titleAbout {
      font-weight: 700;
    }

    span {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
