@import "src/styles/variables";
.wrapper {
  padding: 16px 2px;
  background: #FFFFFF;
  //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 100%;
  color: #000000;
  border-radius: 16px;

  .verticalText {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    * {
      text-align: center;
      display: block;
    }
    &.left {
      text-align: left;
      * {
        text-align: left;
      }
    }

  }
  &.left {
    text-align: left;
    padding: 16px;
    .verticalText {
      text-align: left;
    }
  }
  .text {
    margin-left: 16px;
  }
  .description {
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    color: $gray;
    margin-top: 5px;
  }


  i {
    font-size: 32px;
    color: $blue-d1;
  }

  &.vertical {
    flex-direction: column;
    min-height: 100px;
    .bordered {
      border: 1px solid $blue-l4;
      border-radius: $radius-16;
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 32px;
      height: 32px;

    }
  }
  &.left {
    align-items: flex-start;
    &.vertical {
      .bordered {
        width: 48px;
        height: 48px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      text-align: left;
    }
  }
  &.small {
    font-size: 15px;
    line-height: 18px;
    padding: 16px 8px;
  }
}